<template>
  <action-dispatcher
    action="classes/getClass"
    :parameters="$route.params.id"
    @success="success"
  >
    <div class="view-admin-class">
      <button class="btn btn-back mb-4" @click="$router.go(-1)">
        <i class="icon-angle-left mr-2"></i> Voltar
      </button>
      <h1 class="mb-5">{{ info.name }}</h1>

      <action-dispatcher
        action="classes/getStudents"
        :parameters="$route.params.id"
        @success="successStudents"
      >
        <h4>Alunos</h4>

        <div v-if="students.length == 0" class="text-center my-5">
          <p class="text-secondary">
            Ainda não temos alunos cadastrados nesta turma
          </p>
        </div>
        <div
          v-else
          v-for="student in students"
          :key="student.id"
          class="card p-3 mb-4 "
        >
          <div class="d-flex align-items-center">
            <h6 class="mr-3">{{ student.name }}</h6>
            <h6 class="font-weight-normal">{{ student.email }}</h6>
          </div>
        </div>
      </action-dispatcher>
    </div>
  </action-dispatcher>
</template>
<script>
import firebase from "firebase";
import get from "lodash/get";
export default {
  data() {
    return {
      classInfo: null,
      loading: true,
      students: []
    };
  },
  computed: {
    info() {
      return {
        name: get(this.classInfo, "name", ""),
        email: get(this.classInfo, "email", "")
      };
    }
  },
  methods: {
    successStudents(data) {
      this.students = data;
    },
    success(data) {
      this.classInfo = data;
    }
  }
};
</script>
<style>
.view-admin-class {
}
</style>
